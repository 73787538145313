import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div className="text-white py-24">
      <h1>Sorry, that page doesn't exist</h1>
    </div>
    
  </Layout>
)

export default NotFoundPage
